<template>
	<div id="login">
		<div class="loginbox">
			<div class="loginbox-i flc">
				<img class="loginbox-l" src="../../assets/img/logo.png">
				<div class="loginbox-r">
					欢迎登录
				</div>
			</div>
		</div>
		<div class="loginbox-b">
			<div class="loginbox-b-box">
				<div class="loginbox-b-box-t">
					账户登录
				</div>
				<input class="loginbox-b-box-c" type="" name="" id="" v-model="Phone" placeholder="请输入手机号码" />
				<div class="loginbox-b-box-y flc">
					<input class="loginbox-b-box-y-l" type="" name="" v-model="code" id="" placeholder="请输入验证码" />
					<div class="loginbox-b-box-y-r" v-if="shows" @click="sundrySms">
						获取验证码
					</div>
					<div class="loginbox-b-box-y-r" v-else  @click="tishi">
						{{second}}S后重试
					</div>
				</div>
				
				<div class="xieyi" @click="dianji()">
					<div class="xuabuttom">
						<div class="x-child" v-if="showsA">
							
						</div>
					</div>
					<div class="x-text" @click.stop="">
						已阅读并同意<div style="color:#0072FF ;cursor:pointer " @click="yonghuxieyi()">《用户协议》</div>和 <div style="color:#0072FF ;cursor:pointer "  @click="yinsi()">《隐私协议》</div>
					</div>
				</div>
				
				<div class="loginbox-b-box-b" @click="upLogin">
					登录
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		consumerLoginPhone,//手机号码登入
		sundrySms,//获取验证码
	} from "../../utils/index.js"
	export default {
		name: "login",
		data() {
			return {
				Phone:'',
				code:'',
				shows:true,
				second:59,
				showsA:false
			}
		},
		created() {
			this.$forceUpdate()
		},
		methods:{
			dianji(){
				this.showsA=!this.showsA
			},
			yonghuxieyi(){
				this.$router.push('/yonghuxieyi');
			},
			yinsi(){
				this.$router.push('/yinsi');
			},
			sundrySms(){
				if(this.Phone==''){
					this.$message({
						message: '请输入手机号',
						type: 'warning'
					});
					return
				}else if(!(/^1[3456789]\d{9}$/).test(this.Phone)){
						this.$message({
							message: '请输入正确的手机号码',
							type: 'warning'
						});
						this.Phone=''
						return
				}else{
					sundrySms({
						phone:this.Phone
					}).then(res=>{
						//console.log("验证码",res)
						if(res.code==200){
							this.$message({
								message: '发送验证码成功',
								type: 'success'
							});
							this.upcode()
						}
					})
				}
			},
			upcode(){
				this.shows=false
				let interval = setInterval(() => {
					--this.second
					}, 1000)
				setTimeout(() => {
					clearInterval(interval)
					this.shows = true
					this.second = 59
					// this.disabled = false
					// this.changess = true;
				}, 60000)
			},
			tishi(){
				this.$message({
					message:'请勿重复点击',
					type: 'error'
				})
			},
			upLogin(){
				if(this.Phone==''){
					this.$message({
						message: '请输入手机号',
						type: 'warning'
					});
					return
				}else if(!(/^1[3456789]\d{9}$/).test(this.Phone)){
						this.$message({
							message: '请输入正确的手机号码',
							type: 'warning'
						});
						this.Phone=''
						return
				}else if(this.showsA==false){
						this.$message({
							message: '请勾选协议',
							type: 'warning'
						});
						return
				}else if(this.code==''){
						this.$message({
							message: '请输入验证码',
							type: 'error'
						});
						return
				}else{
					this.consumerLoginPhone()
				}
			},
			consumerLoginPhone(){
				consumerLoginPhone({
					phone:this.Phone,
					code:this.code
				}).then(res=>{
					//console.log("手机登录",res)
					if(res.code==200){
						this.$message({
							message: '登入成功',
							type: 'success'
						});
						localStorage.setItem("uid",res.data.id)
						localStorage.setItem("nickname",res.data.nickname)
						localStorage.setItem("headImg",res.data.headImg)
						localStorage.setItem("aid",res.data.aid)
						setTimeout(()=>{
							this.$router.push('/');
						},1000)
						
						return
					}else{
						this.$message({
							message: '登入异常稍后重试',
							type: 'error'
						});
						return
					}
				})
			}
		}
	}
</script>


<style lang="less">
	@import url("login.less");
	
	
	.xieyi{
		margin-top: 25px;
		display: flex;
		align-items: center;
		
		.xuabuttom{
			width: 25px;
			height: 25px;
			background: #EDEDED;
			border: 1px solid #A7A7A7;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor:pointer;
			.x-child{
				width: 15px;
				height: 15px;
				background: #D80000;
				border-radius: 50%;
			}
		}
		.x-text{
			display: flex;
			flex-direction: row;
			margin-left: 20px;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #666666;
		}
	}
</style>
